import SubNav from '../ui/subnav';

const Footer = () => {
    return (  
     <footer className="fixed-bottom text-center">
            <SubNav />
            © everfaery.com {(new Date().getFullYear())}
     </footer>
    );
}
 
export default Footer;