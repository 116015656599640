import axios from 'axios';
import { useState, useEffect } from "react";
import { FlickrAPI } from '../data/api';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const LoadingContent = () => {
	return (
		<div className="text-center">
			<Spinner animation="border" role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
}

const Home = () => {
	return (
		<div className="login-wrapper">
			<h1>Welcome</h1>

		</div>
	);
}

export default Home;
