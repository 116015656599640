import axios from 'axios';
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button'; 
import Spinner from 'react-bootstrap/Spinner';
import { createContext } from 'react';


const LoadingContent = () => {
	return (
		<div className="text-center">
			<Spinner animation="border" role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
}


const Login = (myStatus) => {
	const [isLoggedIn, setisLoggedIn] = useState(null);
	const logIn = () => {
		setisLoggedIn(true);
	};
	const logOut = () => {
		setisLoggedIn(false);
	};

	return (
		<div className="login-wrapper">
			{isLoggedIn ? (
				<button onClick={logOut}>Logout</button>
			) : (
				<button onClick={logIn}>Login</button>
			)}
		</div>
	);	
}

export default Login;
