import { Route, Routes } from 'react-router-dom';
import { useState } from "react";
import { createContext } from 'react';
import { useContext } from 'react';

// Stylesheets
import './assets/css/reset.css';
import './assets/css/bootstrap.min.css';
import './assets/css/boxicons.min.css';
import './assets/css/main.css';


// Elements
import Footer from './ui/footer';
import HeaderNav from './ui/header';
import Protected from './auth/protected';

// Pages
import Login from './pages/login';
import Home from './pages/home';


const App = () => {
    const [isLoggedIn, setisLoggedIn] = useState(null);

    const logIn = () => {
        setisLoggedIn(true);
    };
    const logOut = () => {
        setisLoggedIn(false);
    };

     return (
        <div className="App">
            <Protected isLoggedIn={isLoggedIn}><HeaderNav /></Protected>
             <div className="container main-content">
                 {isLoggedIn ? (
                     <button onClick={logOut}>Logout</button>
                 ) : (
                     <button onClick={logIn}>Login</button>
                 )}
                <Routes>
                    <Route path="/" element={<Login />} />
                     <Route path="/home" element={<Protected isLoggedIn={isLoggedIn}><Home /></Protected>} />
                </Routes>
            </div>
             <Protected isLoggedIn={isLoggedIn}><Footer /></Protected>


         </div>

     );
}

export default App;
